<template functional>
  <section class="ulsemo-logo">
    <img :src="props.img_src" alt="ulsemo icon">
  </section>
</template>

<script>

export default {
  props: {
    img_src: {
      type: String,
      requited: false,
      default: "/static/icons/ulsemo-icon.svg"
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .ulsemo-logo {
    display: flex;
    align-items: center;
    padding: $half-default-size;
    margin: 0;
    width: fit-content;

    img {
      width: 30px;
      height: 30px;
    }
  }
</style>
